export const GET_ALL_POLICIES_QUERY = `
query getAllPolicies(
  $find: String
  $fromDate: String
  $limit: Int = 10
  $offset: Int = 0
  $partnerId: String
  $productId: String
  $sortField: String
  $sortOrder: String
  $status: String
  $toDate: String
) {
  policies(
    find: $find
    fromDate: $fromDate
    limit: $limit
    offset: $offset
    partnerId: $partnerId
    productId: $productId
    sortField: $sortField
    sortOrder: $sortOrder
    status: $status
    toDate: $toDate
  ) {
      id
      prettyId
      status
      issuedAt
      withdrawnAt
      cancelledAt
      expiredAt
      isTesting
      values
      customer {
        firstName
        lastName
        email
        values
      }
      product {
        displayName
      }
      partner {
        name
      }
      pagination {
        totalCount
        offset
        limit
      }
      startsAt
      endsAt
      scheduledActionsCount {
        total
        scheduled
      }
  }
}
`
